<template>
    <div class="redirect" >
      <a-spin :spinning="true" tip='加载中...' size='large' wrapperClassName='whov'></a-spin>
    </div>
  </template>
<script lang="ts">
</script>
<style lang="less" scoped>
.redirect{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
</style>
